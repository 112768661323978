/**
 * function to handle response from api.
 * this function takes applicationError object,
 * so if there is any error this will show error message
 * if not then show the response mesg.
 */
import { mapGetters } from 'vuex';
import Constants from '@/constants';

const AppMixin = {

  data() {
    return {
      isValidationError: false,
    };
  },

  computed: {
    ...mapGetters(['getApplicationError']),
  },

  methods: {

    /**
     * Generic mixin method to handle response from api call.
     *
     * @param {*} apiResponse
     */
    handleApiResponse(apiResponse, component) {
      console.log('------Utility: handleApiResponse -------------', this.getApplicationError, ' : ', apiResponse, ' : ', component);
      if (this.getApplicationError && this.getApplicationError.errorCode === 0) {
        console.log('----- No Error code -------------');
        if (apiResponse && 'successful' in apiResponse) {
          console.log('----- Successfu msg exist xxx -------------');
          if (apiResponse.code === '409') {
            console.log('----- Resource already exist -------------');
            this.isValidationError = true;
          }
          this.$eventBus.$emit(Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR, {
            successful: apiResponse.successful,
            message: this.$t(`userValidations.${apiResponse.message}`, { item: component }),
          });
        }
      } else {
        console.log('========== handle api else : ', this.$eventBus, ' : ', this.$t('userValidations.ADMIN_GENERIC_ACTION_EXCEPTION'), ' : ', Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR);
        this.$eventBus.$emit(Constants.GLOBAL_EVENT_BUS_FOR_SNACK_BAR, {
          successful: false,
          message: this.$t('userValidations.ADMIN_GENERIC_ACTION_EXCEPTION'),
        });
      }
    },

  },
};

export default AppMixin;
